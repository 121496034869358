exports.components = {
  "component---src-components-result-main-index-js": () => import("./../../../src/components/ResultMain/index.js" /* webpackChunkName: "component---src-components-result-main-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-ja-js": () => import("./../../../src/pages/index.ja.js" /* webpackChunkName: "component---src-pages-index-ja-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-ko-js": () => import("./../../../src/pages/index.ko.js" /* webpackChunkName: "component---src-pages-index-ko-js" */),
  "component---src-pages-index-th-js": () => import("./../../../src/pages/index.th.js" /* webpackChunkName: "component---src-pages-index-th-js" */),
  "component---src-pages-index-zh-hans-js": () => import("./../../../src/pages/index.zh-Hans.js" /* webpackChunkName: "component---src-pages-index-zh-hans-js" */),
  "component---src-pages-index-zh-js": () => import("./../../../src/pages/index.zh.js" /* webpackChunkName: "component---src-pages-index-zh-js" */),
  "component---src-pages-question-index-en-js": () => import("./../../../src/pages/question/index.en.js" /* webpackChunkName: "component---src-pages-question-index-en-js" */),
  "component---src-pages-question-index-ja-js": () => import("./../../../src/pages/question/index.ja.js" /* webpackChunkName: "component---src-pages-question-index-ja-js" */),
  "component---src-pages-question-index-ko-js": () => import("./../../../src/pages/question/index.ko.js" /* webpackChunkName: "component---src-pages-question-index-ko-js" */),
  "component---src-pages-question-index-th-js": () => import("./../../../src/pages/question/index.th.js" /* webpackChunkName: "component---src-pages-question-index-th-js" */),
  "component---src-pages-question-index-zh-hans-js": () => import("./../../../src/pages/question/index.zh-Hans.js" /* webpackChunkName: "component---src-pages-question-index-zh-hans-js" */),
  "component---src-pages-question-index-zh-js": () => import("./../../../src/pages/question/index.zh.js" /* webpackChunkName: "component---src-pages-question-index-zh-js" */)
}

